import React from 'react'
import { NavLink } from 'react-router-dom'

function NavigationButton({ link, title, className }) {
    return (
        <NavLink
            to={link}
            className={`bg-[#BBA085] px-8 py-1 text-[#2C2C54] hover:text-[#BBA085] hover:bg-[#474787] transition-all ${className}`}
        >
            {title}
        </NavLink>
    )
}

export default NavigationButton
