import React, { useState } from "react";
import PageBanner from "../Sections/PageBanner";
import useScrollToHash from "../utilities/useScrollToHash";
import { Helmet } from "react-helmet";
import { FaTimes } from "react-icons/fa";
import { motion } from "framer-motion";

// Array of gallery images with metadata
const galleryImages = [
    {
        src: "https://res.cloudinary.com/ddp8ln1ts/image/upload/v1741385277/Nostalgic/wmziusqeqxawo9g170af.jpg",
        category: "Proposals",
    },
    {
        src: "https://res.cloudinary.com/ddp8ln1ts/image/upload/v1741385277/Nostalgic/hnt8fieq1jbpbe1bbms7.jpg",
        category: "Proposals",
    },
    {
        src: "https://res.cloudinary.com/ddp8ln1ts/image/upload/v1741385277/Nostalgic/de0xv7i5yjx1kgllxd2z.jpg",
        category: "Proposals",
    },
    {
        src: "https://res.cloudinary.com/ddp8ln1ts/image/upload/v1741385278/Nostalgic/cwlgtscq7u97untr8epq.jpg",
        category: "Wedding",
    },
    {
        src: "https://res.cloudinary.com/ddp8ln1ts/image/upload/v1741385278/Nostalgic/xgnwdqmpzn1jw6hynuzf.jpg",
        category: "Graduation",
    },
    {
        src: "https://res.cloudinary.com/ddp8ln1ts/image/upload/v1741385278/Nostalgic/q97tey63ev98zwgohfe4.jpg",
        category: "Special Events",
    },
    {
        src: "https://res.cloudinary.com/ddp8ln1ts/image/upload/v1741385278/Nostalgic/gx05xkgikjbaz4d3djyl.jpg",
        category: "Proposals",
    },
    {
        src: "https://res.cloudinary.com/ddp8ln1ts/image/upload/v1741385278/Nostalgic/ufnwdob7qptgayikvsho.jpg",
        category: "Proposals",
    },
    {
        src: "https://res.cloudinary.com/ddp8ln1ts/image/upload/v1741385277/Nostalgic/hgbc0g5g8xvbx555xhrt.jpg",
        category: "Proposals",
    },
    {
        src: "https://res.cloudinary.com/ddp8ln1ts/image/upload/v1741385277/Nostalgic/agyafq7a8t9je0lwm9ll.jpg",
        category: "Proposals",
    },
];

function Gallery() {
    useScrollToHash();
    const [selectedImage, setSelectedImage] = useState(null);

    // Lightbox handlers
    const openLightbox = (image) => setSelectedImage(image);
    const closeLightbox = () => setSelectedImage(null);

    return (
        <div className="bg-[#2C2C54] text-[#bbbbbb] min-h-screen">
            <Helmet>
                <title>Nostalgic Moments Gallery | Calgary Event Planning</title>
                <meta
                    name="description"
                    content="Browse through the Nostalgic Moments gallery to see how we bring unforgettable events to life. From creative proposals to special celebrations, explore our expertly planned moments."
                />
                <meta
                    name="keywords"
                    content="Calgary event planning, proposal planning gallery, graduation celebration gallery, event planning portfolio, Calgary special events, creative event planning, luxury event planning Calgary, memorable event moments, wedding proposal planning, special celebrations Calgary, event design gallery, event planners Calgary, proposal ideas Calgary, custom event planning, Calgary event organizers"
                />
            </Helmet>

            <PageBanner
                text={`Our Memorable Moments`}
                backgroundImage={
                    "https://storage.googleapis.com/bookfocal-production/images/c63a16b2fabd4d338b70826835f6d268.jpg"
                }
            />

            {/* Gallery Section */}
            <section id="portfolio" className="py-16 bg-[#191930] relative">
                <div className="container mx-auto px-4 md:px-8">
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
                        {galleryImages.map((image, index) => (
                            <motion.div
                                key={index}
                                className="relative aspect-[4/3] overflow-hidden rounded-lg shadow-lg"
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ delay: index * 0.1, duration: 0.5 }}
                            >
                                <img
                                    src={image.src}
                                    alt={image.alt}
                                    className="w-full h-full object-cover cursor-pointer transform transition-all duration-300 hover:scale-110 brightness-75 saturate-50 hover:saturate-[70%] hover:brightness-110"
                                    onClick={() => openLightbox(image)}
                                />
                            </motion.div>
                        ))}
                    </div>
                </div>

                {/* Lightbox */}
                {selectedImage && (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        className="fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center z-50 p-4 overflow-y-auto"
                        onClick={(e) => e.target === e.currentTarget && closeLightbox()}
                    >
                        <div className="relative w-full max-w-4xl h-full flex flex-col items-center justify-center">
                            <div className="max-h-[calc(100vh-8rem)] w-full flex flex-col items-center">
                                <img
                                    src={selectedImage.src}
                                    alt={selectedImage.alt}
                                    className="w-full h-auto max-h-[calc(100vh-12rem)] object-contain rounded-lg shadow-2xl"
                                />
                            </div>
                            <button
                                className="absolute top-4 right-4 p-2 bg-[#2C2C54] text-[#bbbbbb] rounded-full hover:bg-blue-500 hover:text-white transition-colors"
                                onClick={closeLightbox}
                            >
                                <FaTimes size={20} />
                            </button>
                        </div>
                    </motion.div>
                )}
            </section>
        </div>
    );
}

export default Gallery;