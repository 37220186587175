import React, { useEffect, useState } from 'react'
import { CgMenuRight } from 'react-icons/cg';
import { motion } from 'framer-motion';
import { IoMdClose } from 'react-icons/io';
import NavigationLink from '../Components/Navigation/NavigationLink';
import NavigationDropdown from '../Components/Navigation/NavigationDropdown';
import NavigationButton from '../Components/Navigation/NavigationButton';

const menuVariants = {
    hidden: {
        x: '100%',
    },
    show: {
        x: 0,
        transition: {
            ease: [0.6, 0.01, -0.05, 0.9],
        },
    },
    exit: {
        x: '100%',
        transition: {
            type: 'spring',
        },
    },
};
function MobileHeader() {
    const [openMenu, setOpenMenu] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            const threshold = 200;

            if (scrollPosition > threshold) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <nav className='md:hidden text-2xl'>
            <div
                className="text-white  cursor-pointer transition-colors duration-700 flex items-center justify-center h-full"
                onClick={() => setOpenMenu(true)}
            >
                <CgMenuRight className="text-4xl" />
            </div>

            <motion.div
                variants={menuVariants}
                initial={'hidden'}
                animate={openMenu ? 'show' : 'hidden'}
                exit="exit"
                className='bg-[#1f1f3b] shadow-2xl w-full absolute top-0 right-0 max-w-xs h-screen z-20 text-primary'
            >
                <div className='text-4xl absolute z-30 left-4 top-14 cursor-pointer ' onClick={() => setOpenMenu(false)}>
                    <IoMdClose />
                </div>

                <ul className='h-full flex flex-col justify-center items-center gap-y-8 font-primary'>
                    <li>
                        <NavigationButton
                            link={"/booking/"}
                            title={"Book Now"}
                        />
                    </li>
                    <li className=''>
                        <NavigationLink
                            title={`Proposal`}
                            link={"/Proposal/"}
                        />
                    </li>
                    <li className='pl-5'>
                        <NavigationDropdown
                            dropdownTitle={"Special Events"}
                            dropdownLink={"/specialEvents"}
                            links={[
                                { name: 'Birthday', link: '/Birthday/' },
                                { name: 'Anniversaries', link: '/Anniversaries/' },
                                { name: 'Graduation', link: '/Graduation/' },
                                { name: 'Baby Showers', link: '/BabyShowers/' },
                                { name: 'Gender Reveals', link: '/GenderReveals/' },
                                { name: 'Themed Parties', link: '/ThemedParties/' },
                                { name: 'Pre-Wedding Events', link: '/Pre-WeddingEvents/' },
                            ]}
                        />
                    </li>
                    <li>
                        <NavigationLink
                            title={`About`}
                            link={"/#about"}
                        />
                    </li>
                    <li className=''>
                        <NavigationLink
                            title={`Gallery`}
                            link={"/Gallery/"}
                        />
                    </li>
                    <li>
                        <NavigationLink
                            title={"Contact"}
                            link={"/#Contact"}
                        />
                    </li>
                </ul>
            </motion.div>
        </nav>
    );
}

export default MobileHeader
