import React from 'react'
import EventSetupLanding from './EventSetupLanding'


function Home() {
    return (
        <section id="home">
            <EventSetupLanding />
        </section>

    )
}

export default Home
