import React, { useState } from 'react';
import { IoIosArrowDown } from "react-icons/io";
import { motion, AnimatePresence } from 'framer-motion';
import { NavLink } from 'react-router-dom';

const NavigationDropdown = ({ dropdownTitle, dropdownLink, links, className }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const handleToggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleMouseEnter = () => {
        setIsDropdownOpen(true);
    };

    const handleMouseLeave = () => {
        setIsDropdownOpen(false);
    };

    return (
        <div className="relative text-left flex">
            <div className='hover:scale-110 flex'>
                <NavLink
                    to={dropdownLink}
                    className={({ isActive }) =>
                        isActive ? "transition-all flex items-center text-center " : "cursor-pointer flex items-center text-center transition-all"
                            + "hover:text-[#BBA085]" + className
                    }
                >
                    {dropdownTitle}

                </NavLink>
                <motion.button
                    className="inline-block ml-1 text-2xl"
                    initial={{ rotate: 0 }}
                    animate={{ rotate: isDropdownOpen ? 180 : 0 }}
                    onClick={handleToggleDropdown}
                    onMouseEnter={handleMouseEnter}
                >
                    <IoIosArrowDown />
                </motion.button>
            </div>
            <AnimatePresence>
                {isDropdownOpen && (
                    <motion.div
                        className={`absolute z-50 mt-5 w-fit px-5 py-2 drop-shadow-xl text-xl bg-[#1f1f3b] backdrop-blur-xl text-white`}
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 15 }}
                        exit={{ opacity: 0 }}
                        onMouseLeave={handleMouseLeave}
                    >
                        {links.map(({ name, link, clickAction }) => (
                            <NavLink
                                to={link && link}
                                className={({ isActive }) =>
                                    `${isActive && "text-[#BBA085] "} block py-3 text-center hover:text-[#BBA085] font-extralight transition-all hover:scale-110`
                                }
                                // className="block px-2 py-2 text-center  hover:text-[#F99D24] font-thin transition-all hover:scale-105 hover:font-semibold"
                                onClick={() => setIsDropdownOpen(false)}
                                key={name}
                            >
                                {name}
                            </NavLink>
                        ))}
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default NavigationDropdown;
