import React from 'react'
import { VscFoldDown } from "react-icons/vsc";
import { NavLink } from 'react-router-dom';
import ServiceCard from '../Components/ServiceCard';
import proposalImg from "../media/proposalImg.jpg"
import SpecialEventImg from "../media/SpecialEventImg.jpg"
import video from "../media/video.mp4"
import { BsInstagram, BsTiktok } from "react-icons/bs";
import CallToBookingSection from '../Sections/CallToBookingSection';
import useScrollToHash from '../utilities/useScrollToHash';

const EventSetupLanding = () => {
    useScrollToHash();
    return (
        <>
            <div className="h-screen bg-[rgb(6,4,12)] text-[#bbbbbb]">
                <div
                    dangerouslySetInnerHTML={{
                        __html: `<video 
                                loop muted autoplay playsinline
                                src="${video}"
                                class="fixed top-0 left-0 w-screen h-screen object-cover z-0"
                            />`,
                    }}
                ></div>
                <div className="fixed top-0 left-0 w-screen h-full flex flex-col justify-between items-center z-0 text-[#e6ddd6] backdrop-brightness-[40%] px-6">
                    {/* Headline Section */}
                    <div className="my-auto text-center px-6 sm:px-8 lg:px-16">
                        <h1 className="text-4xl sm:text-5xl lg:text-6xl font-playfair font-semibold tracking-wide text-[#BBA085] drop-shadow-xl mb-6">
                            Celebrate the Extraordinary
                        </h1>
                        <p className="mt-4 sm:mt-6 text-lg sm:text-xl lg:text-2xl max-w-3xl mx-auto leading-relaxed text-[#d1c8be] opacity-90">
                            Based in Calgary, we craft timeless celebrations filled with elegance, passion, and unforgettable memories.
                        </p>
                    </div>
                    {/* Scroll Indicator at the Bottom */}
                    <div className="mb-6">
                        <NavLink to="#about">
                            <div className="flex flex-col items-center">
                                <VscFoldDown className="text-5xl text-white hover:text-[#BBA085] transition-colors duration-300 animate-bounce" />
                            </div>
                        </NavLink>
                    </div>
                </div>
            </div>
            <div className="z-20 bg-[#2C2C54] relative rounded-t-3xl">
                {/* About section content */}
                <div id="about" className="section text-center py-24 items-center md:text-left">
                    <h2 className="text-4xl lg:text-5xl md:pb-24 text-center leading-snug text-[#BBA085]">
                        Celebrating Life's Biggest Milestones with Grace and Style
                    </h2>
                    <div className='flex flex-col-reverse md:flex-row md:px-20'>
                        {/* Text Section */}
                        <div className="md:w-2/3 my-auto text-xl lg:text-2xl md:pr-14 leading-relaxed text-[#bbbbbb]">
                            <p>
                                In the heart of Calgary, we specialize in transforming your milestones into unforgettable celebrations. From romantic proposals to cherished gatherings, every detail is crafted with passion and precision.
                            </p>
                            <p className="mt-4">
                                Your story deserves to be celebrated, and we're here to make it extraordinary. Let us create moments you'll treasure forever.
                            </p>
                        </div>
                        {/* Image Section */}
                        <div className="md:w-2/3 md:pb-0 pb-10 mt-8 lg:mt-0 flex justify-center lg:justify-end">
                            <img
                                alt="celebration setup"
                                src={"https://pertiwiphotography.com/wp-content/uploads/2024/02/banff-proposal-photographer-10.jpg"}
                                className="w-full max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg shadow-lg object-cover"
                            />
                        </div>
                    </div>
                </div>

                {/* Services */}
                <div id='services' className="section flex flex-col pb-28 py-10 text-center">
                    <div className='pb-10'>
                        <h2 className="text-3xl leading-tight font-playfair text-[#BBA085]">
                            Services
                        </h2>
                        <h2 className="text-5xl my-2 leading-tight font-playfair text-[#bbbbbb]">
                            What We Offer
                        </h2>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                        <ServiceCard
                            img={proposalImg}
                            title={"Proposal"}
                            disc={
                                "Create unforgettable moments with tailored proposal planning services designed to make your big question truly special."
                            }
                            offerings={[
                                "Tailored Proposal Packages Designed Just for You",
                                "Venue Selection & Seamless Coordination",
                                "Stunning Photography & Videography to Capture Every Moment",
                                "Custom Event Design & Décor",
                                "Flawless Day-of Coordination to Ensure Perfection",
                                "Unique Keepsakes & Mementos to Cherish Forever",
                            ]}
                            link={"/Proposal/"}
                        />
                        {/* <ServiceCard
                            img={GraduationImg}
                            title={"Graduation"}
                            disc={
                                "Celebrate milestones with bespoke graduation event planning, ensuring a memorable day for graduates and their loved ones."
                            }
                            offerings={[
                                "Event Planning and Coordination",
                                "Venue Selection and Decoration",
                                "Professional Photography and Videography",
                                "Graduation Party Themes and Décor",
                                "Custom Invitations and Announcements",
                                "Keepsakes and Personalized Gifts",
                            ]}
                            link={"/Proposal/"}
                        /> */}
                        <ServiceCard
                            img={SpecialEventImg}
                            title={"Special Events"}
                            disc={"Creating unforgettable moments through tailored planning and flawless execution."}
                            offerings={[
                                "Event Planning and Coordination",
                                "Venue Selection and Styling",
                                "Personalized Decor and Design",
                                "Photography and Videography Services",
                                "Day-of Event Management",
                            ]}
                            link={"/SpecialEvents/"}
                        />
                    </div>
                </div>

                {/* Portfolio Section */}
                {/* <div id='portfolio' className=" flex flex-col py-28 bg-cover bg-center text-center bg-[#191930]">
                    <div className="section px-8 flex flex-col gap-y-5">
                        <div className='pb-5'>
                            <h2 className="text-3xl leading-tight font-playfair text-[#BBA085]">
                                Portfolio
                            </h2>
                            <h2 className="text-5xl my-2 leading-tight font-playfair text-[#bbbbbb]">
                                What We Do
                            </h2>
                        </div>
                        <p className="text-lg sm:text-xl lg:text-2xl max-w-4xl mx-auto leading-relaxed text-[#bbbbbb]">
                            Capturing Your Moments, Preserving Your Memories. Explore our gallery to see examples of the magic we bring to each event. From intimate proposals to grand weddings, we ensure each photo captures the spirit of your celebration.
                        </p>
                        <div className="columns-2 md:columns-3 gap-4 mt-8 space-y-4">
                            <img
                                src="https://paisleyphotos.ca/wp-content/uploads/2014/10/2014-10-01_0006.jpg"
                                alt="Event 1"
                                className="w-full h-auto rounded-lg shadow-md hover:scale-105 transform transition-transform duration-300 brightness-50 hover:brightness-75"
                            />
                            <img
                                src="https://images.squarespace-cdn.com/content/v1/63c03c48d4f725460eaf4b2e/64f7ce42-15c7-4f15-b358-d173ddd78d2b/Surprise+Proposal-Extra-105.jpg"
                                alt="Event 2"
                                className="w-full h-auto rounded-lg shadow-md hover:scale-105 transform transition-transform duration-300 brightness-50 hover:brightness-75"
                            />
                            <img
                                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSQavISFSFTsHu_Gt-1h5ZF03jLGVajuZv1gA&s"
                                alt="Event 3"
                                className="w-full h-auto rounded-lg shadow-md hover:scale-105 transform transition-transform duration-300 brightness-50 hover:brightness-75"
                            />
                            <img
                                src="https://static.wixstatic.com/media/cc19ea_fa9dcb6d51ca483fbcc6cd8ac0dec162~mv2.jpg/v1/crop/x_0,y_401,w_1537,h_1504/fill/w_520,h_508,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/1F4337F2-502F-4735-8308-7D4CEB4C5CCE_JPG.jpg"
                                alt="Event 4"
                                className="w-full h-auto rounded-lg shadow-md hover:scale-105 transform transition-transform duration-300 brightness-50 hover:brightness-75"
                            />
                            <img
                                src="https://www.ashleydaphnephotography.com/wp-content/uploads/2020/03/Ashley-Daphne-Photography-Calgary-Couple-Wedding-Photographer-Engagement-Photos-Proposal-Photos_0031.jpg"
                                alt="Event 5"
                                className="w-full h-auto rounded-lg shadow-md hover:scale-105 transform transition-transform duration-300 brightness-50 hover:brightness-75"
                            />
                            <img
                                src="https://paisleyphotos.ca/wp-content/uploads/2014/10/2014-10-01_0004.jpg"
                                alt="Event 6"
                                className="w-full h-auto rounded-lg shadow-md hover:scale-105 transform transition-transform duration-300 brightness-50 hover:brightness-75"
                            />
                        </div>
                        <NavLink
                            to="/portfolio/"
                            className="w-fit mx-auto bg-[#474787] hover:bg-[#2C2C54] text-white lg:text-lg font-medium py-4 px-28 transition-all backdrop-blur-md shadow-lg hover:scale-105 rounded-md mt-8"
                        >
                            View Portfolio
                        </NavLink>
                    </div>
                </div> */}

                {/* Testimonials Section */}
                {/* <div className="section flex flex-col py-16 text-center">
                    <div className='pb-20'>
                        <h2 className="text-3xl leading-tight font-playfair text-[#BBA085]">
                            Testimonials
                        </h2>
                        <h2 className="text-5xl my-2 leading-tight font-playfair text-[#bbbbbb]">
                            What Our Clients Have to Say
                        </h2>
                    </div>
                    <div className="text-lg sm:text-xl lg:text-2xl  text-[#bbbbbb]">
                        <div className="flex flex-col md:flex-row items-center mb-12 gap-6">
                            <img
                                src="https://example.com/sophia-mark.jpg"
                                alt="Sophia & Mark"
                                className="w-24 h-24 md:w-36 md:h-36 rounded-full object-cover shadow-md"
                            />
                            <p className="text-lg sm:text-xl leading-relaxed text-[#bbbbbb]">
                                "Beyond the Proposal made our wedding day a breeze. They were attentive, thoughtful, and made sure every little detail was exactly how we envisioned."
                                <br /> – <em>Sophia & Mark</em>
                            </p>
                        </div>
                        <div className="flex flex-col md:flex-row items-center mb-12 gap-6">
                            <img
                                src="https://example.com/rachel-daniel.jpg"
                                alt="Rachel & Daniel"
                                className="w-24 h-24 md:w-36 md:h-36 rounded-full object-cover shadow-md"
                            />
                            <p className="text-lg sm:text-xl leading-relaxed text-[#bbbbbb]">
                                "From the proposal to our wedding day, they were with us every step of the way. The team’s creativity and calm approach made our day perfect."
                                <br /> – <em>Rachel & Daniel</em>
                            </p>
                        </div>
                        <div className="flex flex-col md:flex-row items-center mb-12 gap-6">
                            <img
                                src="https://example.com/alex-jamie.jpg"
                                alt="Alex & Jamie"
                                className="w-24 h-24 md:w-36 md:h-36 rounded-full object-cover shadow-md"
                            />
                            <p className="text-lg sm:text-xl leading-relaxed text-[#bbbbbb]">
                                "Their team handled everything with such professionalism and warmth. They took care of all the details so we could truly enjoy our day."
                                <br /> – <em>Alex & Jamie</em>
                            </p>
                        </div>
                    </div>
                </div> */}

                {/* <FAQSection /> */}

                {/* Planning Guide Section */}
                {/* <div className="relative z-20 max-w-screen-lg mx-auto text-center flex flex-col py-16">
                    <h2 className="text-2xl sm:text-5xl mb-8 leading-tight font-playfair text-[#BBA085]">
                        Planning Guide
                    </h2>
                    <p className="text-lg sm:text-xl lg:text-2xl max-w-4xl mx-auto leading-relaxed text-[#bbbbbb]">
                        Not sure where to begin? Check out our free guide with tips for a seamless planning process, including budgeting, timelines, and design tips.
                    </p>
                    <a href="#guide" className="btn bg-[#474787] hover:bg-[#2C2C54] text-base lg:text-lg font-medium py-2 px-6 transition-all backdrop-blur-md shadow-lg hover:scale-105 rounded-md mt-8">
                        Download Free Guide
                    </a>
                </div> */}

                {/* Booking Call-to-Action Section */}
                <CallToBookingSection />

                {/* Social Media & Blog Section */}
                <div className="section flex flex-col py-16 text-center">
                    <div className="pb-10">
                        <h2 className="text-3xl leading-tight font-playfair text-[#BBA085]">
                            Social Media
                        </h2>
                        <h2 className="text-5xl my-2 leading-tight font-playfair text-[#bbbbbb]">
                            Follow Our Journey
                        </h2>
                    </div>
                    <p className="text-lg sm:text-xl lg:text-2xl max-w-4xl mx-auto leading-relaxed text-[#bbbbbb]">
                        Keep up with the latest trends, tips, and stories from our events.
                    </p>
                    <div className="flex justify-center gap-6 mt-8">

                        {/* Instagram Icon */}
                        <a
                            href="https://www.instagram.com/nostalgic_moments_yyc"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-[#BBA085] text-4xl hover:text-[#d8d8d8] transition-colors duration-300"
                        >
                            <BsInstagram />
                        </a>
                        {/* TikTok Icon */}
                        <a
                            href="https://www.instagram.com/nostalgic_moments_yyc"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-[#BBA085] text-4xl hover:text-[#d8d8d8] transition-colors duration-300"
                        >
                            <BsTiktok />
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EventSetupLanding;